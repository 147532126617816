<template>
  <div class="app-container home">
    <file-upload v-model="form.file" :limit="1"/>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      // 版本号
      version: "3.8.6",
      // 表单参数
      form: {
        file: ''
      },
    };
  },
  methods: {
    goTarget(href) {
      window.open(href, "_blank");
    }
  }
};
</script>
<style>
</style>

