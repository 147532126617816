<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <h3 class="title">闪电收书后台管理系统</h3>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="用户名"
        >
          <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="phoneNumber">
        <el-input
          v-model="loginForm.phoneNumber"
          type="text"
          auto-complete="off"
          placeholder="手机号"
        >
          <svg-icon slot="prefix" icon-class="phone" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          v-model="loginForm.code"
          type="code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
        </el-input>
        <div class="login-code">
          <el-button @click="getCode" :disabled="codeBtnText!='发送验证码'" size="small" type="primary" >
            {{codeBtnText}}
          </el-button>
        </div>
      </el-form-item>

      <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住用户名和手机号</el-checkbox>
      <el-form-item style="width:100%;">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
        <div style="float: right;" v-if="register">
          <router-link class="link-type" :to="'/register'">立即注册</router-link>
        </div>
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>沪ICP备2023021984号</span>
    </div>
  </div>
</template>

<script>
import { sendSms } from "@/api/login";
import Cookies from "js-cookie";

export default {
  name: "Login",
  data() {
    return {
      timerInterval: null,
      codeBtnText: '发送验证码',
      codeCount: 0,
      codeUrl: "",
      loginForm: {
        username: "",
        phoneNumber: "",
        code: "",
        rememberMe: false,
        uuid: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的用户名" }
        ],
        phoneNumber: [
          { required: true, trigger: "blur", message: "请输入您的手机号" }
        ],
        code: [
          { required: true, trigger: "blur", message: "请输入您的验证码" }
        ]
      },
      loading: false,
      // 注册开关
      register: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    this.getCookie();
  },
  methods: {
    getCode() {
      const username = this.loginForm.username
      const phoneNumber = this.loginForm.phoneNumber
      sendSms(username, phoneNumber).then(response=>{
        this.intervalCodeButton();
        this.$modal.msgSuccess("发送验证码成功");
      })
    },
    intervalCodeButton(){
      this.codeCount = 60;
      let self = this;
      this.codeBtnText = '倒计时 '+ this.codeCount + ' s';
      this.timerInterval = setInterval(
          () => {
            if (self.codeCount > 0) {
              self.codeCount = self.codeCount - 1 ;
              self.codeBtnText = '倒计时 '+ self.codeCount + ' s';
          } else {
            clearInterval(self.timerInterval);
            self.timerInterval = null;
            self.codeBtnText = '发送验证码';
            self.codeCount = 0;
            // console.log('clearInterval 时间到,销毁')
          }
        },1000
      );
    },
    getCookie() {
      const username = Cookies.get("username");
      const phoneNumber = Cookies.get("phoneNumber");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        phoneNumber: phoneNumber === undefined ? this.loginForm.phoneNumber : phoneNumber,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("phoneNumber", this.loginForm.phoneNumber, { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("phoneNumber");
            Cookies.remove('rememberMe');
          }
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/images/login-background.jpg");
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
}
</style>
