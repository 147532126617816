var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container home" },
    [
      _c("file-upload", {
        attrs: { limit: 1 },
        model: {
          value: _vm.form.file,
          callback: function ($$v) {
            _vm.$set(_vm.form, "file", $$v)
          },
          expression: "form.file",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }