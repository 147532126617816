import request from '@/utils/request'
import { parseStrEmpty } from "@/utils/ruoyi";

// 查询用户列表
export function listSeller(query) {
  return request({
    url: '/sellers/seller/list_seller',
    method: 'get',
    params: query
  })
}

export function allSeller() {
  return request({
    url: '/sellers/seller/all_seller',
    method: 'get'
  })
}


// 查询用户详细
export function getUser(userId) {
  return request({
    url: '/sellers/seller/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/sellers/seller/add_seller',
    method: 'post',
    data: data
  })
}


// 修改用户
export function updateUser(data) {
  return request({
    url: '/sellers/seller',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: '/sellers/seller/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd(sellerId, password) {
  const data = {
    sellerId,
    password
  }
  return request({
    url: '/sellers/seller/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(id, status) {
  const data = {
    id,
    status
  }
  return request({
    url: '/sellers/seller/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/sellers/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/sellers/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/sellers/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/sellers/profile/avatar',
    method: 'post',
    data: data
  })
}

// 查询授权角色
export function getAuthRole(userId) {
  return request({
    url: '/sellers/seller/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole(data) {
  return request({
    url: '/sellers/seller/authRole',
    method: 'put',
    params: data
  })
}

//充值
export function recharge(data) {
  return request({
    url: '/sellers/seller/recharge',
    method: 'put',
    data: data
  })
}

export function balance(query) {
  return request({
    url: '/sellers/balance',
    method: 'get',
    params: query
  })
}
